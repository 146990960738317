(function() {
  var $overlay = $('#overlay');

  if ($overlay.length < 1) {
    return
  }

  if (window.location.href.indexOf('#no-overlay') != -1) {
    $overlay.removeClass('show');
    return;
  }

  var $overlayImage = $('#overlay-img');
  var $cursor = $overlay.find('.overlay__cursor');

  var closeTimer = null

  $overlay.on('mousemove', handleMouseMove)
  $(document).on('click', '#overlay', closeOverlay);
  $(window).on('scroll', closeOverlay);
  $overlayImage.one('load', handleImageLoaded);


  function closeOverlay() {
    clearTimeout(closeTimer)

    if ($overlay.hasClass('show')) {
      $overlayImage.css('transform', '');
      $overlay.removeClass('show');

      var stateObj = { url: window.location.href };
      history.replaceState(stateObj, document.title, window.location.href + "#no-overlay");

      $(document).off('click', '#overlay', closeOverlay)
      $(window).off('scroll', closeOverlay)
      $('#overlay-img').off('mousemove', handleMouseMove)
    }
  }

  function handleMouseMove(event) {
    var x = event.clientX
    var y = event.clientY
    $cursor.addClass('is-active')
    $cursor[0].style.transform = 'translateX(' + x + 'px)' + ' translateY(' + y + 'px)'
  }

  function handleImageLoaded() {
    $overlay.addClass('is-ready')

    closeTimer = setTimeout(closeOverlay, 2000); //konec transition v css - opacity na obrazku
  }

  if ($overlayImage[0].complete) {
    $overlayImage.trigger('load');
  }
})();