// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'
window.jQuery = $
// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/giant.show-if'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()
window.$ = $


import Scroll from './services/Scroll'
Scroll.useNative()


//STARY JS - postupne refaktorovat do komponent
import 'corejs-typeahead'
import 'malihu-custom-scrollbar-plugin'
import 'lightgallery'
import jQueryBridget from 'jquery-bridget'
import Flickity from 'flickity'
Flickity.setJQuery(jQuery)
jQueryBridget('flickity', Flickity, jQuery)
import './plugins-old/_select2'
import './plugins-old/slick'
// import './plugins-old/netteForms'
import './modules/Logo'
import './modules/LogoMobile'
import './modules/_tabs'
import './modules/accord'
import './modules/add-to-pack-validation'
import './modules/add-to-samples'
import './modules/ajax-once'
import './modules/antispam'
import './modules/autosubmit'
import './modules/cart-table-autosubmit'
import './modules/categories'
import './modules/data-href'
import './modules/data-phone'
import './modules/filter-autosubmit'
import './modules/filter-nav-mobile'
import './modules/flickity'
import './modules/form-validation'
import './modules/gtm-event'
import './modules/hamburger'
import './modules/has-max-samples'
import './modules/hide'
import './modules/input-zoom-stop'
import './modules/lightgallery.init'
import './modules/load-more-scroll'
import './modules/loading'
import './modules/nette-ajax-reload'
import './modules/not-available'
import './modules/number-front'
import './modules/options-list'
import './modules/order-button'
import './modules/osobni-prevzeti'
import './modules/overlay'
import './modules/packing'
import './modules/personalization'
import './modules/radio-uncheck'
import './modules/scroll-stop'
import './modules/scroll'
import './modules/search'
import './modules/select2'
import './modules/toggle'
import './modules/typeahead'
import './modules/unfold'
import './modules/url'
import './modules/view-select'
import './modules/voucher-add-to-cart'
import './modules/voucher-form-error'
import './modules/voucher-form'








if ($.nette) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { loadComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
// import lazySizes from 'lazysizes'
// lazySizes.cfg.lazyClass = 'is-lazy'
// lazySizes.cfg.loadingClass = 'is-lazy-loading'
// lazySizes.cfg.loadedClass = 'is-lazy-loaded'

// docasne pridane pro odmazani starych cookies pro listy
document.cookie = 'ingredientsCookies=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
