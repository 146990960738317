import enquire from 'enquire.js'

// init
enquire.register('(min-width: 75em)', {
    match: function () {
        $('select:not(.Quantity-select)').select2();
    }
});

$('select:not(.Quantity-select)').on("select2:opening", function() {
    // $(this).select2("val", "");
    setTimeout(function () {
        $('.select2-results__option--highlighted').removeClass('select2-results__option--highlighted');
    },100);
});

$(window).on('showIf:show', function(event) {
    enquire.register('(min-width: 75em)', {
        match: function () {
            $('select:not(.Quantity-select)').select2();
        }
    });
});

enquire.register('(max-width: 74.9375em)', {
    match: function () {
        $('select:not(.Quantity-select)').find('option').each(function () {
            if ($(this).html() == '') {
                $(this).remove();
            }
        });
    }
});