(function() {

    var $tabs, $list, $tiles, defaultview, $listToggle, $tileToggle, $additionalInfo;

    $tabs = $('.view-select');
    $list = $('#list-view');
    $tiles = $('#tile-view');

    // controls

    $listToggle = $tabs.find('.view-select__item--list');
    $tileToggle = $tabs.find('.view-select__item--tile');

    $additionalInfo = $('.view-select__additional-info, .view-select__product-name')

    defaultview = null; // list or tile

    if($tabs && $tabs.data('view')){
        console.log('taby', $tabs);
        defaultview = $tabs.data('view');
    }
    else if($list.data('view') == "show-only"){
        defaultview = 'list'
    }
    else{
        defaultview = "tiles"
    }

    if (defaultview == 'list'){
        $tiles.hide();
        $listToggle.addClass('active');
        $list.show()
        $additionalInfo.addClass('visible');
    }

    else if(defaultview == 'tiles'){
        $list.hide()
        $tileToggle.addClass('active');
        $tiles.show();
    }

    $listToggle.click(function(){
        // activate(this.data('ref'));
        activate($(this).data('ref'));
    })

    $tileToggle.click(function(){
        activate($(this).data('ref'));
    })

    function reset(){
        $tiles.hide()
        $list.hide()
        $listToggle.removeClass('active');
        $tileToggle.removeClass('active');
        $additionalInfo.removeClass('visible');
    }

    function activate(select){
        reset()
        if (select == 'list'){
            $listToggle.addClass('active');
            $list.show();
            $additionalInfo.addClass('visible');
        }
        else{
            $tileToggle.addClass('active');
            $tiles.show();
        }
    }

})();

