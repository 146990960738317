(function() {
    $.nette.ext('snippet--auto-url', {
        success: function (payload) {
            if (payload) {
                if (payload.currentUrl !== undefined) {
                    if (location.hostname === 'localhost'){
                        payload.currentUrl = location.origin + payload.currentUrl.replace(/^.*\/\/[^\/]+/, '')
                    }
                    var prevHash = window.location.hash;
                    var stateObj = {url: payload.currentUrl};
                    history.replaceState(stateObj, document.title, payload.currentUrl + prevHash);
                }
            }
        }
    });
})();