(function() {

    function check ($elem) {
        if ($elem.parent('label').hasClass('not-available')) {
            $('#watcher-product-name').text($elem.parent('label').data('name'));
            $('.add-to-cart').addClass('hide');
            $('.Watcher').addClass('show');
        } else {
            $('.add-to-cart').removeClass('hide');
            $('.Watcher').removeClass('show');
        }
    }

    $(document).on('change', '.product-Variations input', function () {
        check($(this));
    });

    check($('.product-Variations input:checked'));

})();