(function() {

    if($('[data-unfold]').length == 0) {
        return 0;
    }

    var $unfold = $('[data-unfold]');

    $unfold.on('click', function () {
        var target = $('#' + $(this).data('unfold'));

        target.toggleClass('show');

        $(this).toggleClass('unfolded');
    });

})();