import Flickity from 'flickity'
import enquire from 'enquire.js'

(function() {
    if ($('.product-Display').length == 0) {
        return 0;
    }

    var mainPic, $carousel;

    $.nette.ext('snippet--reload-slick', {
        success: function (payload) {
            if (payload.currentUrl.indexOf('changeCurrency') != -1) {
                attachFlickity();
            }
        }
    });


    function attachFlickity () {
        var dots = ($('.product-Display-item').length > 1)
        let carousels = []

        var initialIndex = 0

        if ($('[data-main-image-id]').length) {
            var mainPictureId = $('[data-main-image-id]').data('main-image-id');
            var $mainPicture = $('[data-image-id="' + mainPictureId + '"]');
            var $picture = $mainPicture.parent('.product-Display-item');
            initialIndex = $picture.index()
        }

        carousels = $('.product-Display')
            .toArray()
            .map(element => {
                const flickity = new Flickity(element, {
                    cellSelector: '.product-Display-item',
                    prevNextButtons: false,
                    initialIndex: initialIndex,
                    pageDots: dots,
                    lazyLoad: true,
                    adaptiveHeight: true,
                    accessibility: false,
                    fade: true,
                    wrapAround: true
                })

                $(element).data('flickity', flickity)

                return flickity
            })

        const carousel = carousels[0]

        checkArrowsVisibility()

        //vybrani varianty v radio listu, kdyz se zmeni slide
        carousel.on('change', function(event, index) {
            var $activeItem = $(carousel.getCellElements()[index])
            var variantId = $activeItem.find('[data-variant-id]').attr('data-variant-id')
            var $radioElement = $('[data-variant="' + variantId + '"]:not(.not-available) input')
            setTimeout(function() {
                if ($radioElement.length > 0) {
                    $radioElement.prop('checked', true)
                }
            }, 0);
        })

        $(document).on('click', '[data-variant]', function (event) {
            selectSlideByVariantNumber($(this).data('variant'))
        });

        if (!dots) $('.product-Display-arrow').addClass('is-hidden')

        $('.product-Display-arrow').click(function(event) {
            event.preventDefault();

            if ($(this).hasClass('product-Display-arrow--prev')) {
                $('.product-Display').data('flickity').previous()
            } else {
                $('.product-Display').data('flickity').next()
            }
        });

        $('[data-variant]').each(function() {
            var input = $(this).find('input')[0]
            if (!input) {
                return
            }
            if (input.checked) {
                selectSlideByVariantNumber($(this).data('variant'))
            }
        })

        function selectSlideByVariantNumber(num) {
            var $pictures = $('[data-variant-id="' + num + '"]');
            var $picture = $pictures.parent('.product-Display-item');
            $('.input--number input').val('1');
            $('.product-Display').data('flickity').select($picture.index())
        }

        function checkArrowsVisibility() {
            if (carousel.slides.length > 1) {
                $('.product-Display-arrow').removeClass('is-hidden')
            }
        }

        carousel.resize()



        /* tabs */

        var $slidesToShow = ($('.Tabs .product-List-item').length > 6) ? 6 : $('.Tabs .product-List-item').length

        $('.Tabs .product-List').slick({
            arrows: false,
            infinite: false,
            slidesToShow: $slidesToShow,
            slidesToScroll: $slidesToShow,
            easing: 'easeInOutCirc',
            initialSlide: 0,
            engeFriction: true,
            accessibility: false,
            touchMove: false,
            arrows: true
        });

        function toDesktopTabs() {
            $slidesToShow = ($('.Tabs .product-List-item').length > 6) ? 6 : $('.Tabs .product-List-item').length;
            resetSlides($slidesToShow);
        }

        function toTabletTabs() {
            $slidesToShow = ($('.Tabs .product-List-item').length > 3) ? 3 : $('.Tabs .product-List-item').length;
            resetSlides($slidesToShow);
        }

        function toMobTabs() {
            $slidesToShow = ($('.Tabs .product-List-item').length > 2) ? 2 : $('.Tabs .product-List-item').length;
            resetSlides($slidesToShow);
        }

        function resetSlides($size) {
            $('.Tabs .product-List').slick('slickSetOption', 'slidesToShow', $size, false);
            $('.Tabs .product-List').slick('slickSetOption', 'slidesToScroll', $size, true);
        }

        function toDesktop () {
            toDesktopTabs();
        }

        function toTablet () {
            toTabletTabs();
        }

        function toMob () {
            toMobTabs();
        }

        enquire.register('(min-width: 62.5em)', {
            match: toDesktop
        });

        enquire.register('(min-width: 32em) and (max-width: 62.5em)', {
            match: toTablet
        });

        enquire.register('(max-width: 32em)', {
            match: toMob
        });

    }

    attachFlickity();
})();