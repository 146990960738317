(function() {
    $(document).on('click', '.accord__head', function () {
        var $item = $(this).parent().find('.accord__body');
        var active = false;

        if ($(this).parent().hasClass('active')) {
            $item.slideUp(100);
            $(this).parent().removeClass('active');
        } else {
            $item.slideDown(100);
            $(this).parent().addClass('active');
        }
    });
})();