
import enquire from 'enquire.js'

(function() {

    $.nette.ext('snippet--reload', {
        success: function (payload) {
            if (payload.snippets) {
                enquire.register('(min-width: 75em)', {
                    match: function () {
                        $('select:not(.Quantity-select)').select2();
                    }
                });
                if ('nette' in $) {
                    $.nette.ext('snippets').afterQueue.fire($('.main'));
                }
                $(document).trigger('change:dom');
            }

        }
    });

})();