(function() {

    var $target = $('[data-sk-hide]');
    var delivery = $('input[name=delivery]:checked');
    var country = $('input[name=delivery_country]:checked');

    $(document).on('change', 'input[name=delivery_country], input[name=delivery]', function(event) {
        delivery = $('input[name=delivery]:checked');
        country = $('input[name=delivery_country]:checked');
        check();
    });

    function check () {
        if (country.val() == 'sk' || delivery.val() == '1') {
            $target.hide();
        } else {
            $target.show();
        }
    }

    check();

    $(document).on('change:dom', function () {
        $target = $('[data-sk-hide]');
        delivery = $('input[name=delivery]:checked');
        country = $('input[name=delivery_country]:checked');
        check();
    });

})();