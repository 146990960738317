(function() {

    if($('.cart-Table').lenght == 0) {
        return 0;
    }

    $(document).on('change', '.cart-Table input:not([data-no-autosubmit])', function () {
        $(this).closest('form').submit();
    });




})();