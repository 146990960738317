
(function() {

    $.nette.ext('snippet--voucher-form-error', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.voucherFormError !== undefined) {
                    $('.VoucherInput').addClass('VoucherInput--error');
                }
            }

        }
    });

})();