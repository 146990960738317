(function() {

    if ($('.page-voucher').length) {
        return;
    }

    var $button = $('.Button--order');

    $.nette.ext('snippet--products-order', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.addedToCart !== undefined) {
                    var self = $('.add-to-cart .Button--order');
                    var selfPack = $('.add-to-cart .Button--orderPack');

                    self.addClass('ordered');
                    selfPack.addClass('ordered');

                    setTimeout(function () {
                        self.removeClass('ordered');
                        selfPack.removeClass('ordered');
                    }, 3000);

                    $button = $('.Button--order');

                    $button.on('click', function (event) {
                        if (!$('input[name=variant]:checked').length) {

                            event.preventDefault();
                            event.stopPropagation();

                            $('.product-Variations').addClass('product-Variations--choose');

                            setTimeout(function () {
                                $('.product-Variations').removeClass('product-Variations--choose');
                            }, 3000);
                        }
                    });
                }
            }

        }
    });

    $(document).on('mouseover', '.Button--order', function (event) {

        if (!$('input[name=variant]:checked').length) {

            event.preventDefault();
            event.stopPropagation();

            var self = $(this);

            self.addClass('not-chosen');
        }

    });

    $(document).on('mouseleave', '.Button--order', function (event) {
        if (!$('input[name=variant]:checked').length) {

            event.preventDefault();
            event.stopPropagation();

            var self = $(this);

            self.removeClass('not-chosen');
        }

    });

    $button.on('click', function (event) {
        if (!$('input[name=variant]:checked').length) {

            event.preventDefault();
            event.stopPropagation();

            $('.product-Variations').addClass('product-Variations--choose');

            setTimeout(function () {
                $('.product-Variations').removeClass('product-Variations--choose');
            }, 3000);
        }

    });

})();