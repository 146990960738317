;(function() {

    var input_template =
        '<div class="Personalization-input" data-input="replace">' +
            '<div class="Personalization-title">Text personalizace:</div>' +
            '<div class="Personalization-count"><span data-count>0</span>/23</div>' +
            '<input type="text" name="personalizationMessage[]" maxlength="23" required>' +
        '</div>'

    var $personalization = $('[data-module-personalization]')

    if (!$personalization) {
        return
    }

    var $form = $personalization.closest('form')
    var $submit = $form.find('[data-ref="addToCart"]')

    var $quantity_select = $('#frm-addToCartForm-quantity')
    var quantity = $quantity_select.val()

    var inputs = []

    function addInput(number) {
        var curr_template = input_template.replace('replace', i)
        var input_node = $.parseHTML(curr_template)[0]

        $personalization.append(input_node)
        inputs.push(input_node)
    }

    function removeInput(number) {
        var index = number-1

        inputs[index].remove()
        inputs.splice(index, 1);
    }

    if (quantity > 0) {
        for (var i = 1; i <= quantity; i++) {
            addInput(i)
        }
    }

    $quantity_select.change(function () {
        var selected_quantity = $(this).val()
        var curr_quantity = inputs.length

        if (selected_quantity - curr_quantity > 0) {
            for (var i = curr_quantity; i < selected_quantity; i++) {
                addInput(i)
            }
        } else {
            for (var i = curr_quantity; i > selected_quantity; i--) {
                removeInput(i)
            }
        }
    });

    $personalization.on('keyup', 'input[name="personalizationMessage[]"]', function (event) {
        var counter = $(this).closest('.Personalization-input').find('span[data-count]')
        counter.text($(this).val().length)
    })

    $submit.on('click', function (event) {
        event.preventDefault()

        var valid = true

        for (var i = 0; i < inputs.length; i++) {
            if ($(inputs[i]).find('input').val().length == 0) {
                valid = false
            }
        }

        if (valid) {
            $form.removeClass('has-errors')
            $submit.attr('type', 'submit')
        } else {
            $form.addClass('has-errors')
            $submit.attr('type', 'button')
        }
    })

    function reset() {
        for (var i = inputs.length; i > 1; i--) {
            removeInput(i)
        }
    }

    $('.product-Variations input[type="radio"]').change(reset)
})();