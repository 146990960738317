(function() {

    var timer = null, prev;

    $(document)
        .on('change', 'select[data-autosubmit]', function() {
            removeInputOnMediaQuery()
            $(this).closest('form').submit();
        })
        .on('keyup', 'input[data-autosubmit], textarea[data-autosubmit]', function() {
            var delay = ($(this).data('delay')) ? parseInt($(this).data('delay')) : 400

            if (prev != $(this).val()) {
                clearTimeout(timer);
                var $this = $(this);
                timer = setTimeout(function () {
                    if ($this.val().length >= 3) {
                        removeInputOnMediaQuery()
                        $this.closest('form').submit();
                    }

                }, delay);
                prev = $(this).val();
            }
        })
        .on('changeDate', 'input[data-datepicker][data-autosubmit]', function() {
            removeInputOnMediaQuery()
            $(this).closest('form').submit();
        });

    //fujfix na odmazani duplikatnich inputu
    //problem byl takovy, ze je input pro vyber mnozstvi 2x - mobil a desktop a na BE prisel logicky vzdy jen druhy...
    function removeInputOnMediaQuery() {
        var media = (window.matchMedia('(min-width: 48em)').matches) ? 'desktop' : 'mobile'
        $mediaElements = $('[data-media]' + ':not([data-media="' + media +'"])')
        $mediaElements.remove()
    }
})();