
(function() {

    $(document).on('change', '.options-list input', function () {
        if (this.id == 'delivery-6' || this.id == 'delivery-4') {
            $('#billing-same-address').prop('checked', false).parent('.checkbox').hide();
            $('#billing-same-address-box').show();
        } else {
            $('#billing-same-address').prop('checked', true).parent('.checkbox').show();
            $('#billing-same-address-box').hide();
        }
    });

    $(document).on('click', '.options-list label', function () {
        $(this).find('input[type=text]').focus();
    });

})();