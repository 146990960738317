(function() {

    var curr, stateObj;

    var timer = null

    function submitForm() {
        clearTimeout(timer)
        timer = setTimeout(function() {
            $('.filter-nav form').submit()
        }, 200);
    }

    $(document).on('change', '.filter-nav form', function () {
        submitForm()
    });

    $(document).on('click', '.filter-nav a', function (event) {
        if($(this).parent('label').find('input:checked').length) {
            $(this).parent('label').find('input').prop('checked', false);
        } else {
            $(this).parent('label').find('input').prop('checked', true);
        }
        event.preventDefault();

        submitForm()
    });



})();