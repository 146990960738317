import enquire from 'enquire.js'

// na menších zařízeních rozbaluje kategorie filtru
enquire.register('(max-width: 47.9375em)', {
    match: function() {
        $(document).on('click', '.filter-nav__block h3', function () {
            $(this).parent().toggleClass('active');
        });
    },
    unmatch: function () {
        $(document).off('click', '.filter-nav__block h3');
    }
});