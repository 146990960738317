;(function() {

    $(document).on('click', function(event) {
        var $target = $(event.target).closest('[data-gtm-event]')

        if ($target.length && window.dataLayer) {
            try {
                var data = $target.data('gtm-event')
                window.dataLayer.push(data)
            } catch (error) {
                console.warn('dataLayer', error)
            }
        }
    })

})();