(function() {

    $(document).on('keyup', '[data-phone]', function () {
        var $input = $(this);

        if ($input.val().length == 13 && $input.val().indexOf(' ') == -1) {
            var value = $input.val();

            $input.val(value.substring(0, 4) + ' ' + value.substring(4, 7) + ' ' + value.substring(7, 10) + ' ' + value.substring(10, 13));
        }
    });

})();