import {scrollToElement} from '../../services/Scroll';

document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    if (!element) {
        //nevim, proc by nemel byt k dispozici, vzdy se dispatchne na tom elementu... mozna pri swupu v tu chvili pak uz neexistuje element?
        return
    }

    const parentElement = element.closest('.FormItem') || element.parentNode

    let errorElement = null

    if (element.tagName.toLowerCase() != 'button') {
        errorElement = parentElement.querySelector('.InputError')

        //pokud neni na stejny urovni jako input, tak ignorovat (je tu zanoreny input)
        if (errorElement && errorElement.parentNode !== parentElement) {
            errorElement = null
        }
    }

    if (element.type === 'radio') {
        const radios = [...parentElement.querySelectorAll(`input[name="${element.name}"]`)]
        let invalidRadios = []
        radios.forEach(radio => {
            if (radio === element) {
                return
            }
            const isValid = Nette.validateControl(radio,undefined,true)
            if (!isValid) {
                invalidRadios.push(radio)
                return
            }
            if (radio.classList.contains('has-error')) {
                Nette.validateControl(radio)
            }
        })

        if (invalidRadios.length > 0) {
            Nette.validateControl(invalidRadios[0])
        }
    }


    element.classList.remove('has-error');
    element.classList.add('is-valid');

    if (!parentElement.querySelector('.has-error')) {
        parentElement.classList.remove('has-error')

        if (errorElement && parentElement.contains(errorElement)) {
            parentElement.removeChild(errorElement);
        }
    }
});

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    const parentElement = element.closest('.FormItem') || element.parentNode


    let errorElement = parentElement.querySelector('.InputError');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    parentElement.appendChild(errorElement);
    parentElement.classList.add('has-error')
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target));
document.addEventListener('nette:form:invalid', event => {
    const form = event.target
    const error = form.querySelector('.InputError')
    const target = error ? error.closest('.FormItem') : null
    scrollToElement(target ? target : form, { mode: 'auto'});
});