/* formulare sou bez antispamu, jediny sem nasel sign:in na frontu */

(function() {

    $('form').each(function () {

        var $buffer = $('<div />');
        $buffer.html($(this).find('noscript').text());

        var key = $buffer.find('label[for="agreeemail"]').find('strong').text();
        var name = $buffer.find('.anti-fill').attr('name');

        if (name && key) {
            $(this).append($('<input type="hidden" name="' + name + '" value="' + key + '">'));
        }

    });

})();