import enquire from 'enquire.js'

var current = 1;
var timer;

function switchSlide() {
    $('.Logo--mobile .Logo-part').removeClass('is-active');
    $('.Logo--mobile .Logo-part:nth-child(' + current + ')').addClass('is-active');

    current++;

    if (current > $('.Logo--mobile .Logo-part').length) current = 1;
}

enquire.register('(max-width: 31.1875em)', {
    match: function () {
        timer = setInterval(switchSlide, 1500);
        switchSlide();
    },
    unmatch: function () {
        clearInterval(timer);
    }
});