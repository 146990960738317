(function() {
    $.nette.ext('validation-pack', {
        before: function (jqXHR, settings) {
            if (settings.url == "/acqua-di-parma-set-blu-mediterraneo") {
                var $selects = $('#frm-addPackToCartForm select');
                if ($selects.length)
                var valid = true;

                $selects.each (function () {
                    if ($(this).val() == '' || $(this).val() == null) {
                        valid = false;

                        var $parent = $(this).parent().addClass('show-error').append('<p class="input-stock-message">Vyberte vůni</p>');
                        // note here
                        var $error = $(this).parent().find('.input-stock-message');
                        setTimeout(function() {
                            $parent.removeClass('show-error');
                        }, 3000);
                    } else {
                        var $error = $(this).parent().find('.input-stock-message');
                        if ($error) {
                            $error.remove();
                        }
                    }
                })
                return valid;
            }
        }
    });

})();