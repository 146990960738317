(function() {

    $(document).on('change', '.input input, .input textarea', function() {
        var $input = $(this);
        checkInput($input);

        if (this.type === 'file') {
            var $parent = $input.closest('.input');
            $parent.find('.input__file-name').html(this.value ? this.value.match(/[^\/\\]+$/)[0] : '&nbsp;');
            if (!this.value) {
                this.blur();
            }
        }
    });

    $(document).on('blur', '.input input, .input textarea', function() {
        var $input = $(this);
        checkInput($input);

        if (this.type === 'file') {
            var $parent = $input.closest('.input');
            $parent.find('.input__file-name').html(this.value ? this.value.match(/[^\/\\]+$/)[0] : '&nbsp;');
            if (!this.value) {
                this.blur();
            }
        }
    });

    $(document).on('focus', '.input input, .input textarea', function() {
        $(this).closest('.login-tooltip').addClass('input--focused');
    });

    $(document).on('focusout', '.input input, .input textarea', function() {
        $(this).closest('.login-tooltip').removeClass('input--focused');
    });

    $(document).on('click', '.input__placeholder', function() {
        $(this).parent().find('input, textarea').trigger('focus');
    });

    if ($.nette) {
        $.nette.ext('inputs', {
            success: function() {
                refreshInputs();
            }
        });
    }

    function refreshInputs() {
        $('.input:not(.input--always-filled) input, .input:not(.input--always-filled)  textarea').each(function() {
            if (this.value) {
                $(this).closest('.input').addClass('input--filled');
            } else {
                $(this).closest('.input').removeClass('input--filled')
            }
        });
    }

    function checkInput($input) {
        if ( $input.val() !='') {
            $input.closest('.input').addClass('input--filled')
        } else {
            $input.closest('.input').removeClass('input--filled')
        }
    }

    refreshInputs();

    $( document ).on('change:dom', refreshInputs);

})();