
(function() {

    $.nette.ext('snippet--has-max-samples', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.hasMaxSamples !== undefined) {
                    $('.product-Detail-over').addClass('show');
                }
            }

        }
    });

})();