import Bloodhound from 'corejs-typeahead/dist/bloodhound'
import { triggerEvent } from '../utils/dom';

(function() {

    function attach () {
        $('[data-typeahead]').each(function (event) {
            var $this = $(this);

            this.addEventListener('change', () => console.log('test'))

            $.ajax({
                //BE obecne vraci VSECHNY vysledky, neni potreba mu posilat hodnotu z inputu
                url: $this.data('typeahead'), // + $this.val()
                dataType: 'json',
                success: function (result) {
                    var data = result;

                    var suggestAddress = new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('address'),
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        local: data
                    });

                    window.__postOfficeAddresses__ = data;

                    suggestAddress.initialize();

                    $this.typeahead({
                            highlight: true,
                            hint: false
                        },
                        {
                            name: 'name',
                            displayKey: 'address',
                            source: suggestAddress.ttAdapter()
                        }
                    );
                }
            });

            $this.on('typeahead:select', function(event) {
                setTimeout(function() {
                    triggerEvent(event.currentTarget, 'change')
                    $this.trigger('blur');
                }, 0);
            });
        });

    }

    attach();

    $(document).on('change:dom', attach);

})();