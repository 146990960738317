(function() {

    $.nette.ext('snippet--products-order-sample', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.addedToSamples !== undefined) {
                    var self = $('.product-Detail-sample .btn--black');

                    self.addClass('btn--ordered');
                }
            }

        }
    });

})();