import EventBus from '../core/EventBus'
import {EVENTS} from '../core/Events'

(function() {

    $(document).on('click', '[data-toggle]', function (event) {

        event.preventDefault();


        var $toggler = $(this)
        var $content = $('#' + $toggler.data('toggle'));

        if($content.is(':hidden')) {
            $toggler.addClass('is-active')
        } else {
            $toggler.removeClass('is-active')
        }

        $content.slideToggle(300);

        setTimeout(() => {
            EventBus.emit(EVENTS.DOM_CHANGE)
        }, 300)
    });

})();