(function() {
    $(document).on('click', '[data-ajax-once]', function(event) {
        event.preventDefault();
        var href = $(this)[0].href;
        var link = $(this)[0]

        if (link.hasAttribute('data-ajax-running')) {
            return false
        }

        $.nette.ajax({
            url: href,
            start: function(jqXHR) {
                link.setAttribute('data-ajax-running','')
            },
            error: function() {
                link.removeAttribute('data-ajax-running')
            }
        });
    });
})();