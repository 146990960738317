(function() {

    if (!$('.page-voucher').length) {
        return;
    }

    var $Button = $('.Button--order');

    $.nette.ext('snippet--voucher-order', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.addedToCart !== undefined) {
                    var self = $('.add-to-cart .Button--order');

                    self.addClass('ordered');

                    setTimeout(function () {
                        self.removeClass('ordered');
                    }, 3000);
                }
            }

        }
    });

})();