
(function() {
    $.nette.ext('snippet--packing-product-stock-limit', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.overStockCnt !== undefined) {
                    var $packing = $('.packing')
                    if (!$packing.length) {
                        return
                    }

                    var $packingErrors = $packing.find('.packing__errors')
                    var $error = $('<p>'+payload.overStockCntProduct+'</p>')
                    $packingErrors.append($error)
                    setTimeout(function() {
                        $error.remove()
                    }, 4000);
                }
            }
        }
    });

    $.nette.ext('snippet--packing-product-limit', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.overProductLimit !== undefined) {
                    var $packing = $('.packing')
                    if (!$packing.length) {
                        return
                    }

                    var $packingErrors = $packing.find('.packing__errors')
                    var $error = $('<p>'+payload.overProductLimitMessage+'</p>')
                    $packingErrors.append($error)
                    setTimeout(function() {
                        $error.remove()
                    }, 4000);
                }
            }
        }
    });

    $.nette.ext('snippet--packing-incomplete-pack', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.incompletePackMessage !== undefined) {
                    var $packing = $('.packing')
                    if (!$packing.length) {
                        return
                    }

                    var $packingErrors = $packing.find('.packing__errors')
                    var $error = $('<p>'+payload.incompletePackMessage+'</p>')
                    $packingErrors.append($error)
                    setTimeout(function() {
                        $error.remove()
                    }, 4000);
                }
            }
        }
    });
})();