(function() {

    if ($('.product-Display').length == 0) {
        return 0;
    }

    var $lightGallery = $('.product-Display')

    $lightGallery.lightGallery({
        mode: 'lg-fade',
        thumbnail: false,
        download: false,
        autoplayControls: false,
        fullScreen: false,
        zoom: false,
        hash: false,
        selector: '.product-Display-item'
    });

    $.nette.ext('snippet--reload-lightgallery', {
        success: function (payload) {
            if (payload.snippets) {
                $('.product-Display').lightGallery({
                    mode: 'lg-fade',
                    thumbnail: false,
                    download: false,
                    autoplayControls: false,
                    fullScreen: false,
                    zoom: false,
                    hash: false,
                    selector: '.product-Display-item'
                });
            }
        }
    });

})();