import enquire from 'enquire.js'

(function() {

    var c = 0;

    if($('#snippet--products').length == 0) {
        return 0;
    }

    var curr, stateObj;
    var fromTop = 0;
    //var productsFromTop = $('#snippet--products').offset().top;

    $.nette.ext('snippet--products-scroll', {
        success: function (payload) {
            if (payload.snippets) {
                if ('snippet--products' in payload.snippets && payload.searchDone === undefined) {
                    if(payload.loadMore !== undefined) {
                        $('html, body').animate({
                            scrollTop: fromTop  - c
                        }, 500);
                    } else {
                        //stateObj = { url: payload.url };
                        //curr = window.location.host;
                        //history.replaceState(stateObj, document.title, payload.url);


                        $('html, body').animate({
                            scrollTop: $('#snippet--products').offset().top - c
                        }, 500);

                    }
                }
            }

        },
        before: function () {
            fromTop = $('#snippet--products').offset().top + $('#snippet--products').innerHeight();
        }
    });

    enquire.register('(min-width: 48em)', {
        match: function () {
            c = 0;
        }
    });

    enquire.register('(max-width: 47.9375em)', {
        match: function () {
            c = 60;
        }
    });


})();