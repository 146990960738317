import ScrollService from './NativeScroll'
// import ScrollService from './VirtualScroll'
import {queries} from '../../core/config'

ScrollService.getScrollOffset = () => {
    if (window.matchMedia(queries.smallWideMax).matches) {
        const header = document.querySelector('.Header')
        if (!header) {
            return 100
        }
        return header.offsetHeight + header.offsetTop + 30
    } else {
        return 100
    }
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService