(function() {

    var $tabs = $('.Tabs-switch');
    var $target, $contents;

    if ($tabs.length === 0) {
        return;
    } else {
        $tabs.removeClass('no-js');
    }

    function toTabs() {
        var $tabs = $('.Tabs');

        $tabs.each(function (){
            if ($(this).find('.Tabs-contents').length == 0) {
                $(this).append('<div class="Tabs-contents"></div>');
                $contents = $(this).find('.Tabs-contents');

                $(this).find('.Tabs-content').each( function () {
                    $contents.append($(this));
                    //$(this).remove();
                });
            }
        });
    }

    //function toAccord() {
    //
    //    var $tabs = $('.Tabs-switch');
    //
    //    $.each($tabs.find('li a'), function() {
    //        var href = $(this).attr('href');
    //        $(this).parents('li').append($(href));
    //    });
    //    $('.Tabs-contents').remove();
    //}


    $(document).on('click', '.Tabs-switch li > a', function (event) {

        $link = $(this);
        $target = $($link.attr('href'));

        if ($target.length == 0) {
            return
        }
        event.preventDefault();

        $(this).parents('.Tabs').find('.Tabs-content').removeClass('active');
        $target.addClass('active');


        $(this).parents('.Tabs').find('.Tabs-switch li').removeClass('active');
        $link.parent().addClass('active');
        // for mobile
        $(this).parents('li').toggleClass('mobile-opened');

    });

    if ($('.page-info').length) {

        var $hover = $('.Tabs-switch-active');

        $.nette.ext('snippet--tabs-2', {
            success: function (payload) {
                if (payload.snippets) {
                    $hover = $('.Tabs-switch-active');
                    updateActive();
                }
            }
        });

        function updateActive () {
            $hover.css({
                left: $('.Tabs-switch li.active').position().left,
                width: $('.Tabs-switch li.active').innerWidth()
            });
        }

        setTimeout(updateActive, 100);
        updateActive();
        $(document).on('click', '.Tabs-switch li > a', updateActive);
        $(window).on('resize', updateActive);

        return;
    }

    //enquire.register('(min-width: 48em)', {
    //    match: toTabs
    //});

    toTabs();

})();