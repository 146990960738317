(function() {

    $(document).on('click', '[data-hide]', function (event) {

        event.preventDefault();

        var target = $(this).data('hide');
        if (target.indexOf(',') > -1) {

            var targets = target.split(',');

            for (var i = 0; i < targets.length; i++) {
                target = targets[i];

                $('#' + target).css('display', 'none');
            }

        } else {
            $('#' + target).css('display', 'none');
        }

    });

})();