
(function() {

    if($('.VoucherInput-toggle').length == 0) {
        return 0;
    }

    $(document).on('click', '.VoucherInput-toggle', function () {
        $(this).parent('.VoucherInput').toggleClass('show');
        $(this).parent('.VoucherInput').find('input').focus();
    });
})();