(function() {

    var $body = $('body');
    var input, max, scope, prev;

    // over stock limit
    if ($('.product-Detail').length) {
        $(document).on('click', '.input--number__plus', function(event) {
            scope = $(this).parent();
            input = $(this).parent().find('input');
            max = parseInt($('.product-Detail input[type=radio]:checked').data('stock-cnt'));

            console.log(scope);

            if(input.val() >= max) {
                event.stopPropagation();
                input.val(parseInt(input.val()) - 1);
                scope.addClass('show-input-stock-message');
                setTimeout(function() {
                    scope.removeClass('show-input-stock-message');
                }, 4000);
            }
        });
    } else {
        $(document).on('click', '.input--number__plus', function(event) {
            scope = $(this).parent();
            input = $(this).parent().find('input');
            max = parseInt(input.data('stock-cnt'));

            if(input.val() >= max) {
                event.stopPropagation();
                input.val(parseInt(input.val()) - 1);
                scope.addClass('show-input-stock-message');
                setTimeout(function() {
                    scope.removeClass('show-input-stock-message');
                }, 4000);
            }
        });
    }

    // over max
    $(document).on('click', '.input--number__btn', function() {
        if($(this).hasClass('input--number__plus')) {
            input = $(this).parent().find('input');

            if(input.val() < parseInt(input.attr('max'))) {
                input.val(parseInt(input.val()) + 1);
            } else {
                input.parent().addClass('blocked');
                scope.addClass('show-input-stock-message-max');
                setTimeout(function() {
                    input.parent().removeClass('blocked');
                },500);
                setTimeout(function() {
                    scope.removeClass('show-input-stock-message-max');
                }, 4000);
            }
        } else {
            input = $(this).parent().find('input');
            prev = input.val();

            if(input.val() > 1) {
                input.val(parseInt(input.val()) - 1);
            } else {
                scope.addClass('show-input-stock-message-max');
                input.parent().addClass('blocked');
                setTimeout(function() {
                    input.parent().removeClass('blocked');
                },500);
                setTimeout(function() {
                    scope.removeClass('show-input-stock-message-max');
                }, 4000);
            }
        }

        if ($('.cart-Table').length && !$('.show-input-stock-message').length && !$('.show-input-stock-message-max').length) {
            input.closest('form').submit();
        }

    });

    $(document)
        .on('change', '.input--number input', function () {
            if ($(this).val() != parseInt($(this).val())) {
                $(this).val('1');
            } else if ($(this).val() > parseInt($(this).attr('max'))) {
                $(this).val(parseInt($(this).attr('max')));
                var scope = $(this).parents('.input');

                scope.addClass('show-input-stock-message-max');
                setTimeout(function() {
                    scope.removeClass('show-input-stock-message-max');
                }, 4000);
            }
        })
        .on('keyup', '.input--number input', function () {
            if ($(this).val() != parseInt($(this).val())) {
                $(this).val('1');
            }
        });

    // after load
    setTimeout(function () {
        $('.input-stock-message--displayed').removeClass('show');
    }, 4000);

    $.nette.ext('snippet--over-product-limit', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.overStockCnt !== undefined) {
                    $('.input--number, .add-to-cart').addClass('show-input-stock-message');
                    setTimeout(function() {
                        $('.input--number, .add-to-cart').removeClass('show-input-stock-message');
                    }, 4000);
                }
            }

        }
    });

    $.nette.ext('snippet--over-product-stock-limit', {
        success: function (payload) {
            if (payload.snippets) {
                if(payload.overProductLimit !== undefined) {
                    $('.input--number, .add-to-cart').addClass('show-input-stock-message-max');
                    setTimeout(function() {
                        $('.input--number, .add-to-cart').removeClass('show-input-stock-message-max');
                    }, 4000);
                }
            }

        }
    });


})();
