(function() {
    $( document ).ajaxStart(function() {
        $("body").addClass('show-loading');
    });

    $( document ).ajaxStop(function() {
        $("body").addClass('show-loading-complete');

        setTimeout(function () {
            $("body").removeClass('show-loading');
            $("body").removeClass('show-loading-complete');
        }, 500);
    });

})();