(function() {

    $(document).on('click', 'a[data-href]', function(event) {
        event.preventDefault();
        var href = $(this).data('href');
        $.nette.ajax({
            url: href
        });
    });

})();